import { parseQuery, queryCheck, daysInMonth, getRandomInt } from '../helpers/helpers';
import suggester from './suggester';
import 'jquery-ui-bundle/jquery-ui.min.js';
require('jquery-ui-bundle/jquery-ui.min.css');
/*

  TODO

*/

const search = (function () {
  let state = {
    page: null,
    categories: {
      all: 'Összes',
      biz: 'Bizományi',
      arch: 'Archív',
      c_arch: 'Archív színes',
      dkon: 'Kontakt képek',
      mti_hirek: 'MTI hírek',
      mot: 'Magyar Országos Tudósító',
      bizalmas: 'Bizalmas',
      belpolitika: 'Belpolitika',
      külpolitika: 'Külpolitika',
      sport: 'Sport'
    },
    category: null,
    minYear: null,
    maxYear: null,
    startMonth: '01',
    endMonth: '12',
  }

  function _submit(query) {
    _spinner("toggle");
    let url = SITE_URL + state.page;
    if (query.length) {
      url += '?' + query;
    }
    window.location.href = url;
  }

  function _spinner(action) {
    let spinners = [
      '<i class="fas fa-circle-notch fa-spin"></i>',
    ]
    let index = getRandomInt(0, spinners.length - 1);
    if (action === 'toggle') {
      $("#search_btn").html(spinners[index]);
    } else {
      $("#search_btn").html('<i class="fas fa-search"></i>');
    }
  }

  function _addToQuery(stuff = {}) {
    let query = parseQuery(window.location.href);

    let defaultRange = {
      min: null,
      max: null
    };
    if ($('#slider-range').html()) {
      defaultRange = JSON.parse($('#slider-range').attr('data-maxRange'));
      defaultRange = {
        min: defaultRange[0],
        max: defaultRange[1]
      }
    }

    if ((query.min || query.max) && stuff.date) {
      delete query.min;
      delete query.max;
      delete query.sm;
      delete query.em;
    }

    if (query.type) {
      delete query.type
    }

    if (query.date && (stuff.min || stuff.max || stuff.sm || stuff.em)) {
      delete query.date;
    }

    Object.keys(stuff).forEach(key => {
      if (key == 'filter') {
        if (!query.filters) {
          query.filters = []
          query['filters'].push(stuff[key])
        } else {
          if (!Array.isArray(query.filters)) {
            query.filters = [query.filters];
          }
          let added = query.filters.findIndex(f => f == stuff[key])
          if (added >= 0) {
            query.filters.splice(added, 1);
          } else {
            query['filters'].push(stuff[key])
          }
        }
      } else if (key == "category" && stuff[key] === "all") {
        delete query.category;
      } else if (key === "image_type" && stuff[key] === "all") {
        delete query.image_type;
      } else if (key == 'query') {
        query[key] = queryCheck(stuff[key]);
      } else if (key == 'search_filter') {
          const field = stuff[key];
          delete query[field];
      } else {
        if (stuff[key] === false) {
          delete query[key];
        } else {
          query[key] = stuff[key];
        }
      }
    })

    if (query.filters && !query.filters.length) {
      delete query.filters;
    }

    if ((query.sm && query.em) && !query.min && !query.max) {
      query.min = defaultRange.min
      query.max = defaultRange.max
    }

    let string = [];
    Object.keys(query).forEach((key, i, full) => {
      if (Array.isArray(query[key])) {
        let s = [];
        query[key].forEach((item, x, y) => {
          s.push(key + "[]=" + encodeURI(item))
        })
        string.push(s.join('&'));
      } else {
        if (query[key]) {
          string.push(key + "=" + encodeURI(query[key]))
        }
      }
    })
    string = string.join('&')
    _submit(string)
  }

  function _setupDatePicker() {
    let maxRange = JSON.parse($('[name=date_year]').attr('data-maxrange'));
    let min = maxRange[0];
    let max = maxRange[1];
    let __manage = function() {

      if (year && month) {
        if (year >= min && year <= max && state.map[year] > 0) {
          $('[name="date_day"]').removeClass('faded');
          $('[name="date_year"]').removeClass('danger');
          $('#date_submit').removeClass('faded');
        } else {
          $('#date_submit').addClass('faded');
          $('[name="date_year"]').addClass('danger');
        }
      } else {
        $('[name="date_day"]').addClass('faded');
        $('[name="date_day"]').val('');
        $('[name="date_year"]').removeClass('danger');
        $('#date_submit').removeClass('faded');
      }
    }


    let year = $('[name="date_year"]').val().length == 4 ? $('[name="date_year"]').val() : null;
    let month = $('[name="date_month"]').val() ? $('[name="date_month"]').val() : null;
    let day = $('[name="date_day"]').val() ? $('[name="date_day"]').val() : null;
    if (year && month) {
      $('[name="date_day"]').removeClass('faded');
    } else {
      $('.picker-container input').val('')
    }

    $('[name="date_year"]').on('input', function(e) {

      let val = $(e.target).val();

      val = val.split('');
      val = val.filter((v, i) => {
        if (!isNaN(v) && i < 4) {
          return v;
        }
      })
      val = val.join('');

      $('[name="date_year"]').val(val)
      if (val.length === 4) {
        year = val;
      } else {
        year = null;
      }
      __manage();
    })

    $('[name="date_month"]').on('input', function(e) {
      let val = $(e.target).val();
      $("[name=date_day]").val('')
      month = val;
      __manage();
    })

    $('[name="date_day"]').on('input', function (e) {
      let val = $(e.target).val();
      if (val <= daysInMonth(parseInt(month), year)) {
        val = val.split('');
        val = val.filter((v, i) => {
          if (!isNaN(v) && i < 2) {
            return v;
          }
        })
        val = val.join('');
      } else {
        val = val.split('')[0];
      }
      $('[name="date_day"]').val(val)
      day = val;
      __manage();
    })
  }

  function _get_map(callback) {
    let url = SITE_URL + state.page + '/map';
    let params = [];
    if (Object.keys(state.query).length) {
      Object.keys(state.query).forEach(key => {
        if (key !== 'min' && key !== 'max') {
          if (Array.isArray(state.query[key])) {
            state.query[key].forEach(value => {
              params.push(`${key}[]=${encodeURIComponent(value)}`);
            });
          } else {
            params.push(`${key}=${encodeURIComponent(state.query[key])}`);
          }
        }
      })
      params = params.join('&');
      params = '?' + params;

    } else {
      params = '';
    }
    $.ajax({
      url: url + params,
      method: 'GET',
      success: response => {
        response = JSON.parse(response)

        const ranges = response?.data?.facet_ranges?.date;

        let map_for_state = {};
        if (ranges) {
          Object.entries(ranges).forEach(([key, value]) => {
            map_for_state[key] = value;
          })
        } else {
          if (response?.status !== 403) {
            response.forEach((item, index) => {
              if (index % 2 !== 1) {
                let key = item.split('T')[0].split('-')[0];
                map_for_state[key] = response[index  + 1];
              }
            })
          }
        }

        state.map = map_for_state
        callback(response)
      },
      error: error => {
        console.log(error)
      }
    })
  }

  function _render_map(map) {
    let min = parseInt($('#slider-range').attr('data-min'));
    let max = parseInt($('#slider-range').attr('data-max'));
    let selected_year = $('[name=date_year]').val();

    const ranges = map?.data?.facet_ranges?.date;
    let values = null;
    let years = null;

    if (ranges && typeof ranges === 'object') {
      values = Object.values(ranges);
      years = Object.keys(ranges).map(year => parseInt(year));
    } else if (Array.isArray(map)) {
      values = values = map.filter((item, index) => index % 2 !== 0);
      let startYear = parseInt(state.minYear);
      years = values.map((_, index) => startYear + index);
    } else {
      console.error('Invalid data format');
      return;
    }

    let step = 100 / values.length;
    let top = Math.max(...values);
    let height_step = 100 / top;

    let content = values.map((v, i)=> {
      let year = years[i];
      let active = false;

      if (selected_year) {
        active = selected_year == year;
      } else {
        active = year >= min && year <= max;
      }

      return `
      <div
        class="col ${active ? 'active' : ''}"
        style="width: ${step}%; left: ${i * step}%;"
        data-year="${year}"
        title='${year} | ${v} dokumentum'
        data-toggle="tooltip"
        data-placement="left"
      >
        <span
          style="height: ${Math.ceil(v * height_step) < 5 && v * height_step !== 0 ? 5 : v * height_step}%;"
          data-value="${v}"
        >
        </span>
      </div>
      `
    }).join('');
    $('#map').html(content)
    setTimeout(function() {
      $('#map').removeClass('loading');
    }, 100);

    // TODO conflict with other tooltip init??
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
  }

  function _setupListeners() {
    $(function () {
      $('[data-toggle="tooltip"]').tooltip()
    })
    $('#search_form').on('submit', function (e) {
      e.preventDefault();
      let query = $('#search').val();
        let add = {
          query
        }
        if (!$('#search_plus').is('in')) {
          add['date'] = false;
          add['min'] = false;
          add['max'] = false;
        }
        _addToQuery(add)
    })

    $('.tag-row-item.go').click(function () {
      _submit('query="' + $(this).text() + '"')
    })

    $('[data-f]').click(function () {
      let category = $(this).attr('data-f');
      let query = $('#search').val();

      _addToQuery({ category, query });
    })

    $('[data-tech]').click(function () {
      let tech = $(this).attr('data-tech');
      let query = $('#search').val();

      _addToQuery({ image_type: tech, query });
    })

    $("#date_submit").click(function(e) {
      e.preventDefault();
      let maxRange = JSON.parse($('[name=date_year]').attr('data-maxRange'));
      let year = $('[name=date_year]').val();
      let month = $('[name=date_month]').val();
      let day = $('[name=date_day]').val();

      if (month.length === 1) {
        month = '0' + month
      }

      if (day.length === 1) {
        day = '0' + day
      }

      if (!day.length) {
        day = '*';
      }

      if (year.length === 4 && year <= maxRange[1] && year >= maxRange[0] && month && day) {
        let query = $('#search').val();
        _addToQuery({
          date: year + '-' + month + '-' + day,
          query
        })
      } else {
        console.log('nem jó')
      }
    })


    $('#filter-tags [data-filter]').click(function () {
      let filter = $(this).attr('data-filter');
      let query = $('#search').val();
      _addToQuery({ filter, query });
    })

    $('#filter-tags [data-field]').click(function () {
      let field = $(this).attr('data-field');
      let query = $('#search').val();
      _addToQuery({ search_filter: field, query });
    })

    $('.search-plus-switch').click(function () {
      if (state.plus === 'slider') {
        $('.picker-container').removeClass('hidden');
        $('.slide-container').addClass('hidden');
        $('.search-plus-switch').html('Szűrés időszakra')
        state.plus = 'picker';
      } else {
        $('.slide-container').removeClass('hidden');
        $('.picker-container').addClass('hidden');
        $('.search-plus-switch').html('Szűrés napra')
        state.plus = 'slider';
      }
    })

    $('#map').click(function(e) {
      if ($(e.target).attr('data-year') || $(e.target).parent().attr('data-year')) {
        let year = $(e.target).attr('data-year') || $(e.target).parent().attr('data-year');
        let query = $('#search').val();
        _addToQuery({
          min: year,
          max: year,
          query
        })
      }
    })

    if ($('#search_plus').html()) {
      _setupDatePicker();


      let maxRange = JSON.parse($('[data-maxrange]').attr('data-maxrange'));
      let activeRange = JSON.parse($('[data-activerange]').attr('data-activerange'));
      let min = $('[data-min]').attr('data-min');
      let max = $('[data-max]').attr('data-max');

      let select = function (month, id) {
        let months = ['Január', 'Február', 'Március', 'Április', 'Május', 'Június', 'Július', 'Augusztus', 'Szeptember', 'Október', 'November', 'December'];
        let options = months.map((m, i) => `<option value=${i + 1} ${month == i + 1 ? 'selected' : ''}>${m}</option>`).join('');
        return `
          <select id="${id}">${options}</select>
        `
      };

      $("#slider-range").slider({
        range: true,
        min: maxRange[0],
        max: maxRange[1],
        values: [min, max],
        slide: function (event, ui) {
          $("#amount").html(ui.values[0] + `${select(state.startMonth, 'from')} - ` + ui.values[1] + `${select(state.endMonth, 'to')}`);
          if ($('.map-container').html()) {
            $('#map .col').removeClass('active');
            Array.from($('#map .col')).forEach(item => {
              if ($(item).attr('data-year') >= ui.values[0] && $(item).attr('data-year') <= ui.values[1]) {
                $(item).addClass('active')
              }
            })
          }
        },
        stop: function (event, ui) {
          let range = [ui.values[0], ui.values[1]];
          let query = $('#search').val();
          _addToQuery({
            query,
            min: range[0],
            max: range[1]
          })
        }
      });

      $("#amount").html($("#slider-range").slider("values", 0) + `${select(state.startMonth, 'from')} - ` + $("#slider-range").slider("values", 1) + `${select(state.endMonth, 'to')}`);
      $('#amount select').on('change', function () {
        let startMonth = '0' + $('#from').val();
        startMonth = startMonth.substring(startMonth.length - 2);
        let endMonth = '0' + $('#to').val();
        endMonth = endMonth.substring(endMonth.length - 2);
        let months = [startMonth, endMonth];
        console.log(months)
        let query = $('#search').val();
        _addToQuery({
          query,
          sm: startMonth,
          em: endMonth
        })
      })

      if (activeRange.length) {
        let years = maxRange[1] - maxRange[0];
        let start = activeRange[0] - maxRange[0];
        let end = activeRange[1] - maxRange[0];
        let step = years / 100;
        let left = start / step;
        let right = 100 - (end / step);
        $('#slider-range').prepend(`<div class="active-range" style="left: ${left}%; right: ${right}%;"></div>`)
        if (left + right === 100) {
          $('#slider-range').addClass('dot');
        }
      }
    }

    $('.results-sort-dropdown .dropdown-menu a').click(e => {
      e.preventDefault();
      let sort = $(e.target).attr('data-sort');
      if (sort) {
        _addToQuery({sort})
      }
    })

  }

  function _setupSort() {
    let texts = {
      'relevance': 'relevancia szerint',
      'date asc': 'dátum szerint növekvő',
      'date desc': 'dátum szerint csökkenő'
    }
    if (state.query.sort) {
      console.log(state.query.sort)
      $('#active_sort').html(texts[state.query.sort])
    } else {
      $('#active_sort').html(texts['relevance'])
    }
  }

  return {
    init: () => {
      state.page = $('#search').attr('data-scope') ? $('#search').attr('data-scope').split('/')[0] : null;
      let query = parseQuery(window.location.href);
      state.query = query
      //console.log(query)
      state.startMonth = query && query.sm ? parseInt(query.sm) : 1;
      state.endMonth = query && query.em ? parseInt(query.em) : 12;
      if ($('.slide-container').html()) {
        state.minYear = JSON.parse($('#slider-range').attr('data-maxrange'))[0]
        state.maxYear = JSON.parse($('#slider-range').attr('data-maxrange'))[1]
      }

      state.plus = window.location.href.includes('date=') ? 'picker' : 'slider'
      console.log(state.plus)
      if (state.plus === 'slider') {
        $('.search-plus-switch').html('Szűrés napra')
      } else {
        $('.search-plus-switch').html('Szűrés időszakra')
      }

      $('#search').focus();

      if ($('.map-container').html()) {
        _get_map(map => {
          _render_map(map);
        })
      }

      _setupListeners();
      _setupSort();
      // suggester.init();
    }
  }
})()

export default search;
